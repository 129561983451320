import {
  Card,
  Box,
  Typography,
  CardActionArea,
  Tooltip,
  styled,
  Chip,
} from "@mui/material";
import React from "react";
import { WorkType } from "../../@types/workType";
import { useTranslation } from "react-i18next";
import Tag from "../common/Tag";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WorkItemTension from "./WorkItemTension";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import { useSelector } from "react-redux";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";

const Root: any = styled(Card, {
  shouldForwardProp: (props) => props !== "isSelected",
})(({ theme, isSelected }: any) => ({
  borderRadius: "20px",
  border: isSelected ? `1px solid ${theme.palette.primary.main}` : "auto",
}));

const CardContent: any = styled(CardActionArea, {
  shouldForwardProp: (props) => props !== "small",
})(({ theme, small }: any) => ({
  width: "100%",
  padding: small ? "0px 10px" : "15px",
  // minHeight: small ? "80px" : "100px",
  height: "150px",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    height: "auto",
  },
}));

const Index = styled(Box)(({ theme }: any) => ({
  backgroundColor: theme.palette.primary.main,
  minWidth: "50px",
  minHeight: "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
}));

const isValidColor = (color: string) => {
  return color && color.startsWith("#");
};

const WorkListItem: React.FC<{
  work: WorkType;
  index: number;
  handleCategory?: any;
  handleClick: (work: WorkType) => void;
  size?: "small" | "medium";
  isSelected?: boolean;
  context?: string;
}> = ({
  work,
  index,
  handleCategory,
  handleClick,
  size,
  isSelected,
  context,
}) => {
  const { t }: any = useTranslation("common");
  const workSearch = useSelector(
    (state: any) => state?.home?.paramjson?.workSearch
  );

  return (
    <Root
      isSelected={isSelected}
      sx={{ mt: size === "small" ? "10px" : "15px" }}
    >
      <CardContent
        sx={{ flexWrap: { xs: "wrap", sm: "nowrap" } }}
        small={size === "small"}
        onClick={() => handleClick(work)}
      >
        <Index>
          <Typography variant="h5" sx={{ color: "white" }}>
            {index}.
          </Typography>
        </Index>
        <Box>
          <Box
            sx={{
              ml: { xs: 1, sm: 2 },
              mb: { xs: 2, sm: 0 },
              mt: { xs: 2, sm: 0 },
            }}
            display="flex"
            flexDirection="column"
            flexWrap="wrap"
          >
            {work?.type === "category" && work?.code?.length === 5 && (
              <Box sx={{ mr: 1 }}>
                <img
                  src={`/static/icons/work_cat/${work?.code}.png`}
                  width="30"
                  alt="icon"
                />
              </Box>
            )}
            <Typography variant="body1" fontWeight="bold">
              {work.label}{" "}
            </Typography>

            <Box sx={{ opacity: "0.6" }}>
              <Typography
                variant="body1"
                onMouseDown={(event: any) =>
                  handleCategory && event.stopPropagation()
                }
                onClick={(event: any) => {
                  if (handleCategory) {
                    event.stopPropagation();
                    event.preventDefault();
                    handleCategory(work.category.id);
                  }
                }}
              >
                {work?.category?.label}
              </Typography>
            </Box>
          </Box>
          {work?.otherDetails?.countProject > 0 && context === "cre" && (
            <Box
              sx={{
                ml: { xs: 1, sm: 2 },
                mb: { xs: 2, sm: 0 },
              }}
            >
              <Tag
                color="success"
                title={`${work?.otherDetails?.countProject} projet sur ce métier`}
                // image="/static/icons/graduation-hat.svg"
                icon={<EmojiObjectsIcon sx={{ fontSize: "20px" }} />}
              />
            </Box>
          )}

          {work?.studyLevel && (
            <Box
              sx={{
                ml: { xs: 1, sm: 2 },
                mb: { xs: 2, sm: 0 },
              }}
            >
              <Tag
                title={work?.studyLevel?.label}
                // image="/static/icons/graduation-hat.svg"
                icon={<SchoolOutlinedIcon sx={{ fontSize: "20px" }} />}
              />
            </Box>
          )}
          {work?.tags?.length > 0 ? (
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              sx={{ ml: 1 }}
            >
              {work?.tags?.map((tag: any) => (
                <Chip
                  key={tag.tag.label}
                  label={tag.tag.label}
                  sx={{
                    m: 0.5,
                    fontSize: "12px",
                    height: "24px",
                    backgroundColor: isValidColor(tag?.tag?.color)
                      ? tag?.tag?.color
                      : "#708CFF",
                    color: "white",
                  }}
                />
              ))}
            </Box>
          ) : null}
        </Box>
        <Box
          sx={{ ml: { xs: "block", sm: "auto" } }}
          display="flex"
          alignItems="center"
          justifyContent="space-around"
        >
          {work?.workVideos?.length > 0 && !workSearch?.hideVideo && (
            <Tooltip title={t("work_sheet.video_available")}>
              <YouTubeIcon sx={{ fontSize: "20px", mr: 2, color: "#F61C0D" }} />
            </Tooltip>
          )}
          {work?.type !== "category" && !workSearch?.hideTension && (
            <WorkItemTension value={work?.otherDetails?.tension} />
          )}
        </Box>
      </CardContent>
    </Root>
  );
};

export default WorkListItem;
