import React from "react";
import NewSkillsSection from "../skills/newSkills/NewSkillsSection";
import WorkSkillsHeader from "./WorkSkillsHeader";
import { useSelector } from "react-redux";
import { Card, CardContent } from "@mui/material";

const NewWorkSkills: React.FC<any> = ({
  id,
  personId,
  reload,
  workLabel,
  type = "technical",
}) => {
  const context = useSelector((state: any) => state.auth.context);

  return (
    <Card>
      <CardContent>
        <WorkSkillsHeader
          type={type}
          compare={true}
          context={context}
          updated={true}
        />
        <NewSkillsSection
          personId={personId}
          workId={id}
          workLabel={workLabel}
          transversal={type === "transversal"}
        />
      </CardContent>
    </Card>
  );
};

export default NewWorkSkills;
