import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import HomeIcon from "../../icons/menu/home";
import ProfileIcon from "../../icons/menu/profile";
import TestIcon from "../../icons/menu/test";
import NavigationRenderer from "../NavigationRenderer";
import ResourcesMenuIcon from "../../components/ressources/ResourcesMenuIcon";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import WorkIcon from "../../icons/menu/work";
import { matchesPathSegment } from "../../Utils";

const RecruiterContextMenu: React.FC<{
  handleLinkClick: any;
}> = ({ handleLinkClick }: any) => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const context = useSelector((state: any) => state.auth.context);
  const menu = useSelector((state: any) => state.menu);

  const matchPath = useCallback(
    (segment: string) => matchesPathSegment(segment, location.pathname),
    [location.pathname]
  );

  const links = [
    {
      icon: <HomeIcon active={matchPath("home")} context={context} size={25} />,
      label: t("home"),
      path: "/app/home",
      active: matchPath("home"),
    },
    {
      label: t("common_advisor.folder.label_title_plural", { context }),
      icon: (
        <TestIcon active={matchPath("campaign")} context={context} size={25} />
      ),
      path: "/app/campaign/list",
      active: matchPath("campaign"),
    },

    {
      label: t("common_advisor.profile.label_title_plural", { context }),
      icon: (
        <ProfileIcon
          active={matchPath("candidate") || matchPath("profile")}
          context={context}
          size={25}
        />
      ),
      path: "/app/candidate/list",
      active: matchPath("candidate") || matchPath("profile"),
    },
    menu?.third_party && {
      label: t("third_party.label"),
      icon: <PeopleAltIcon />,
      path: "/app/third_party/list",
      active: matchPath("third_party"),
    },
    menu?.works && {
      icon: <WorkIcon />,
      label: t("work_suggest.work_label"),
      path: "/app/work/list",
      active: matchPath("app/work"),
    },
    menu?.resources && {
      icon: <ResourcesMenuIcon />,
      label: t("resources.title"),
      path: "/app/resources",
      active: matchPath("resources"),
    },
  ].filter((el: any) => el);
  return <NavigationRenderer links={links} handleLinkClick={handleLinkClick} />;
};

export default RecruiterContextMenu;
