import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  styled,
  Theme,
} from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import NestedMenu from "./NestedMenu";

// todo : Liens doivent etre en secondary light
const CustomNavLink = styled(NavLink)(({ theme }: any) => ({
  color: "inherit",
  textDecoration: "none",
  "&.active": {
    color: theme.palette.common.white,
    "& *": {
      fontWeight: "bold",
    },
  },
}));

const NavItem: any = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active }: { active: boolean } & any) => ({
  marginTop: 12,
  borderLeft: "2px solid transparent",
  height: "45px",
  "&:hover": {
    backgroundColor: active ? "inherit" : "auto ",
  },
}));

const BoxLink: any = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }: { theme: Theme; active: boolean } & any) => ({
  borderRadius: theme.shape.borderRadius8,
  height: "45px",
  width: "225px",
  marginLeft: "-35px",
  backgroundColor: active ? theme.palette.primary.main : "auto",
}));

export default function MenuNav({ links, handleLinkClick }: any) {
  return (
    <Box className="menunav" sx={{ mt: 2 }}>
      {links?.map((link: any, index: number) => {
        return link.path ? (
          <CustomNavLink
            key={index}
            to={link.path}
            className={`${link.active ? "active" : ""}`}
          >
            <Box display="flex" alignItems="center">
              {/* {link.active && <div className={classes.navLinkDiv}></div>} */}
              <NavItem
                active={link.active}
                sx={{ pl: 7 }}
                button
                key={index}
                onClick={handleLinkClick}
              >
                <BoxLink
                  display="flex"
                  alignItems="center"
                  active={link.active}
                >
                  <ListItemIcon sx={{ ml: 4 }}>{link.icon}</ListItemIcon>
                  <ListItemText primary={link.label} />
                </BoxLink>
              </NavItem>
            </Box>
          </CustomNavLink>
        ) : link.links ? (
          <NestedMenu
            key={index}
            handleLinkClick={handleLinkClick}
            menu={link}
          />
        ) : (
          <NavItem button key={index} sx={{ pl: 5 }} onClick={handleLinkClick}>
            <ListItemIcon>{link.icon}</ListItemIcon>
            <ListItemText primary={link.label} />
          </NavItem>
        );
      })}
    </Box>
  );
}
